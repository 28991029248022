<script>
import { LOAD_CURRENT_STORE_INVENTORIES } from "@/store/actions.type";
import { mapState } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import BackToSettings from "@/views/settings/BackToSettings.vue";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay.vue";
import PageHeader from "@/views/payouts/components/PageHeader.vue";
import ShopApiService from "@/common/api/shop.service";

export default {
  name: 'Notifications',

  components: {
    BackToSettings,
    DataPreparationOverlay,
    PageHeader
  },

  data() {
    return {
      newEmail: null,
      loading: false,
    }
  },

  validations: {
    newEmail: { required, email },
  },

  created: async function() {
    if(!this.currentShop) {
      this.loading = true
      await this.$store.dispatch(`shop/init`).then(() => {
        if (this.currentShop != null) {
          this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
        }
      });
      this.newEmail = this.currentShop.email;
    } else {
      this.newEmail = this.currentShop.email;
    }
    this.loading = false
  },

  computed: {
    ...mapState("shop", ["currentShop"]),
    checkEmailErrors() {
      const emailErrors = [];
      if (!this.$v.newEmail.$dirty) return emailErrors;
      !this.$v.newEmail.email && emailErrors.push("Must be valid e-mail");
      !this.$v.newEmail.required && emailErrors.push("E-mail is required");
      return emailErrors;
    },
    emailChanged() {
      return this.newEmail != (this.currentShop && this.currentShop.email);
    },
  },

  watch: {
    newEmail(newValue) {
      if (newValue === null) {
        this.$store.dispatch(`shop/loadCurrentShop`);
      }
    },
  },

  methods: {
    updateShopInfo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        ShopApiService.updateShopInfo(this.currentShop.id, { email: this.newEmail}).finally(() => {
          this.$store.dispatch(`shop/loadCurrentShop`);
          this.loading = false;
        });
      }
    },
  }
}
</script>

<template>
  <section class="v2 notifications">
    <data-preparation-overlay v-if="loading" />

    <template v-else>
      <BackToSettings />

      <!-- Page Header -->
      <PageHeader pageDescription="">
        <template #title>Notification settings</template>
      </PageHeader>

      <div class="p-t-1" style="border-bottom: solid 1px rgba(224, 224, 224, 1);"></div>

      <v-row class="m-t-4">
        <v-col cols="6">
          <h4>Preferred contact method</h4>
          <h5 class="m-t-4">Email</h5>
          <p style="color: rgba(168, 168, 168, 1);">The email address where you'd like to receive emails and system alerts</p>
        </v-col>
        <v-col cols="6">
          <v-row class="m-t-5 pt-2">
            <v-col cols="8">
              <v-text-field
                dense
                outlined
                v-model="newEmail"
                @change="$v.newEmail.$touch()"
                :error-messages="checkEmailErrors"
                height="46"
              />
            </v-col>
            <v-col cols="4">
              <v-btn elevation="0" class="btn btn-border inverse lg w-100" @click="updateShopInfo" :disabled="!emailChanged" :loading="loading">
                Update email
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </section>
</template>

<style lang="scss">
  .notifications {
    width: 1024px;
    margin: { left: auto; right: auto };
  }
</style>
